<template>
  <el-card>
    <section>
      <canvas ref="beerCanvas"></canvas>
      <div class="coming_content">
        <h1>Coming Soon</h1>
        <div class="separator_container">
          <div class="separator line_separator">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="320.864" height="320.864" viewBox="0 0 320.864 320.864">
                <path fill="#fff"></path>
              </svg>
            </span>
          </div>
        </div>
        <h3>In development. Writing, drawing, developing...</h3>
      </div>
    </section>
  </el-card>
  </template>
  
  <script>
  export default {
    data() {
      return {
        particles: [],
        particleCount: 280,
      };
    },
    mounted() {
      this.animateParticles();
    },
    methods: {
      createParticle(canvas) {
        return {
          x: Math.random() * canvas.width,
          y: canvas.height + Math.random() * 300,
          speed: 1 + Math.random(),
          radius: Math.random() * 3,
          opacity: (Math.random() * 100) / 1000,
        };
      },
      drawParticles(ctx, canvas) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.globalCompositeOperation = 'lighter';
        this.particles.forEach((p, index) => {
          ctx.beginPath();
          ctx.fillStyle = `rgba(255,255,255,${p.opacity})`;
          ctx.arc(p.x, p.y, p.radius, 0, Math.PI * 2, false);
          ctx.fill();
          p.y -= p.speed;
          if (p.y <= -10) {
            this.particles[index] = this.createParticle(canvas);
          }
        });
      },
      animateParticles() {
        const canvas = this.$refs.beerCanvas;
        if (!canvas) return;
        const ctx = canvas.getContext('2d');
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
  
        for (let i = 0; i < this.particleCount; i++) {
          this.particles.push(this.createParticle(canvas));
        }
  
        const loop = () => {
          requestAnimationFrame(loop);
          this.drawParticles(ctx, canvas);
        };
        loop();
      },
    },
  };
  </script>
  
  <style scoped>
  @import url('https://fonts.googleapis.com/css?family=Montserrat');
  
  body, html {
    display: block;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
  }
  
  section {
    position: relative;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, #c39f9f, #ab671d96, #935513cf);
  }
  
  canvas {
   /* position: absolute;
    top: 0;
    left: 0; */
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  
  .coming_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    color: white;
    text-align: center;
    z-index: 101;
  }
  
  .coming_content h1 {
    font-size: 5.625em;
    margin: 0;
    letter-spacing: 2px;
    text-align: center;
  }
  
  .separator_container {
    width: 100%;
    display: block;
    text-align: center;
    margin: 12px 0;
  }
  
  .separator {
    color: white;
    margin: 0 auto 1em;
    width: 11em;
  }
  
  .line_separator svg {
    width: 30px;
    height: 20px;
  }
  
  .line_separator:before,
  .line_separator:after {
    display: block;
    width: 40%;
    content: ' ';
    margin-top: 14px;
    border: 1px solid white;
  }
  
  .line_separator:before {
    float: left;
  }
  
  .line_separator:after {
    float: right;
  }
  
  .coming_content h3 {
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 2px;
    line-height: 2;
    font-size: 1em;
    font-weight: 400;
    text-align: center;
    margin: 0;
  }
  </style>
  